import PageWrapper from "../../commons/PageWrapper";
import Hero from "../../components/Hero";
import About from "../../components/About";
import Features from "../../components/Features";
import Stats from "../../components/Stats";
import Details from "../../components/Details";
import Testimonials from "../../components/Testimonials";
import Pricing from "../../components/Pricing";
import Contact from "../../components/Contact";
import * as amplitude from '@amplitude/analytics-browser';
import {useEffect, useState} from "react";
const Home = () => {
    const [tracked , setTracked] = useState(false)
    useEffect(() => {
        if(!tracked){
            amplitude.track('FILEDIS WEB VISIT');
            setTracked(true)
        }
    } , [])
    return (
        <PageWrapper isHeaderAvailable={true}>
            <Hero/>
            <About/>
            <Stats/>
            <Details/>
            {/*<Testimonials/>*/}
            <Pricing/>
            <Contact/>

        </PageWrapper>
    )
}
export default Home
