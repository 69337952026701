import {useTranslation} from "react-i18next";

const About = () => {
    const [t] = useTranslation()
  return <>
      <section id="about" className="about section">
          <div className="container" data-aos="fade-up" data-aos-delay={100}>
              <div className="row align-items-xl-center gy-5">
                  <div className="col-xl-5 content">
                      <h3>{t("A propos")}</h3>
                      <h2>{t("Filedis est votre solution pour fidéliser vos clients")}</h2>
                      <p>
                          {t("Fidéliser les clients est essentiel pour toute entreprise cherchant à assurer sa pérennité et sa croissance. Un client fidèle non seulement augmente la stabilité des revenus par des achats répétés, mais devient également un ambassadeur de la marque, recommandant les produits et services à son entourage. Cela réduit les coûts d'acquisition de nouveaux clients, car conserver un client existant est souvent moins coûteux que d'en attirer de nouveaux. De plus, la fidélisation permet de mieux comprendre les attentes des clients, facilitant ainsi l'amélioration continue de l'offre et renforçant la relation de confiance entre l'entreprise et ses consommateurs.")}
                      </p>
                      <p>
                          {t("Fidéliser vos clients vous permets:")}
                      </p>
                      {/*<a href="#" className="read-more">
                          <span>Read More</span>
                          <i className="bi bi-arrow-right"/>
                      </a>*/}
                  </div>
                  <div className="col-xl-7">
                      <div className="row gy-4 icon-boxes">
                          <div className="col-md-6" data-aos="fade-up" data-aos-delay={200}>
                              <div className="icon-box">
                                  <i className="bi bi-buildings" />
                                  <h3>{t("Expension de votre commerce")}</h3>
                                  <p>
                                      {t("Les clients sont la base de l'expension de votre comerce et son épanuissement")}
                                  </p>
                              </div>
                          </div>{" "}
                          {/* End Icon Box */}
                          <div className="col-md-6" data-aos="fade-up" data-aos-delay={300}>
                              <div className="icon-box">
                                  <i className="bi bi-clipboard-pulse" />
                                  <h3>{t("Optimisation IA")}</h3>
                                  <p>
                                      {t("Suivez les recommendations fournis par notre IA pour optimiser le rendement de votre commerce")}
                                  </p>
                              </div>
                          </div>{" "}
                          {/* End Icon Box */}
                          <div className="col-md-6" data-aos="fade-up" data-aos-delay={400}>
                              <div className="icon-box">
                                  <i className="bi bi-command" />
                                  <h3>Sécurisé par Blockain</h3>
                                  <p>
                                      {t("Vos transactions sont sécuriser par systeme de Blockain robuste qui garantie l'authenticité de tous les échanges")}
                                  </p>
                              </div>
                          </div>{" "}
                          {/* End Icon Box */}
                          <div className="col-md-6" data-aos="fade-up" data-aos-delay={500}>
                              <div className="icon-box">
                                  <i className="bi bi-graph-up-arrow" />
                                  <h3>{t("Compagne évenmentiels")}</h3>
                                  <p>
                                      {t("Filedis vous aide à croitre votre chiffre d'affaire en faisant parvenir vos évenements a vos clients les plus fidéles et à ceux qui sont suseptible d'acheter")}
                                  </p>
                              </div>
                          </div>{" "}
                          {/* End Icon Box */}
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {/* /About Section */}
  </>

}
export default About
