import {useTranslation} from "react-i18next";

const Pricing = () => {
    const [t] = useTranslation()
  return <>
      <section id="pricing" className="pricing section">
          {/* Section Title */}
          <div className="container section-title" data-aos="fade-up">
              <h2>{t("Prix")}</h2>
              <div>

                  <span className="description-title">{t("Notre forfait")}</span>
              </div>
          </div>
          {/* End Section Title */}
          <div className="container">
              <div className="row gy-4">
                  <div className="col-lg-4" data-aos="zoom-in" data-aos-delay={100}>

                  </div>
                  {/* End Pricing Item */}
                  <div className="col-lg-4" data-aos="zoom-in" data-aos-delay={200}>
                      <div className="pricing-item featured">
                          <p className="popular">{t("Popular")}</p>
                          <h3>Business Plan</h3>
                          <p className="description">
                              {t("Pack business contenant tout les fonctionallités pour votre commerce")}
                          </p>
                          <h4>
                              <sup>€</sup>14.99<span> {t("/ month")}</span>
                          </h4>
                          <a href="https://play.google.com/store/apps/details?id=com.filedis.filedispaymentseller" target={"_blank"} className="cta-btn" style={{display: "flex" , flexDirection: "row", justifyContent:"center" ,gap: 10}}>
                              {/*t("Télécharger l'application")*/}
                              <i className={"bi bi-android"}></i>
                              {t("Seller Android App")}
                          </a>

                          <a href="https://apps.apple.com/fr/app/filedis-pro/id6670335013" target={"_blank"}className="cta-btn" style={{display: "flex" , flexDirection: "row", justifyContent:"center" ,gap: 10}}>
                              {/*t("Télécharger l'application")*/}
                              <i className={"bi bi-apple"}></i>
                              {t("Seller IOS App")}
                          </a>

                          <ul>
                              <li>
                                  <i className="bi bi-check" />{" "}
                                  <span>{t("Gestion de fidelité")}</span>
                              </li>
                              <li>
                                  <i className="bi bi-check" />{" "}
                                  <span>{t("5 campagne evenementiels / mois")}</span>
                              </li>
                              <li>
                                  <i className="bi bi-check" />{" "}
                                  <span>{t("Statistique de rendement")}</span>
                              </li>

                          </ul>
                      </div>
                  </div>
                  {/* End Pricing Item */}
                  <div className="col-lg-4" data-aos="zoom-in" data-aos-delay={300}>

                  </div>
                  {/* End Pricing Item */}
              </div>
          </div>
      </section>
      {/* /Pricing Section */}
  </>

}
export default Pricing
