import {PureIncrement} from 'pure_counter';
import {useTranslation} from "react-i18next";


const Stats = () => {
    const [t] = useTranslation()
    return <>
        <section id="stats" className="stats section light-background">
            <div className="container" data-aos="fade-up" data-aos-delay={100}>
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-12 d-flex flex-column align-items-center">
                        <i className="bi bi-emoji-smile"/>
                        <div className="stats-item">

                            <PureIncrement
                                start={0}
                                end={150}
                                duration={4}
                                className="purecounter"
                            />
                            <p>{t("Comerce a travers le monde")}</p>
                        </div>
                    </div>
                    {/* End Stats Item */}
                    <div className="col-lg-4 col-md-12 d-flex flex-column align-items-center">
                        <i className="bi bi-journal-richtext"/>
                        <div className="stats-item">
                            <PureIncrement
                                start={0}
                                end={3500}
                                duration={4}
                                className="purecounter"
                            />
                            <p>{t("Transaction par jours")}</p>
                        </div>
                    </div>
                    {/* End Stats Item */}
                    <div className="col-lg-4 col-md-12 d-flex flex-column align-items-center">
                        <i className="bi bi-people-fill"/>
                        <div className="stats-item">
                            <PureIncrement
                                start={0}
                                end={15}
                                duration={4}
                                className="purecounter"
                            />
                            <p>{t("Equipes")}</p>
                        </div>
                    </div>
                    {/* End Stats Item */}
                </div>
            </div>
        </section>
        {/* /Stats Section */}
    </>

}
export default Stats
