import {useTranslation} from "react-i18next";

const Details = () => {
    const [t] = useTranslation()
    return <>
        <section id="details" className="details section">
            {/* Section Title */}
            <div className="container section-title" data-aos="fade-up">
                <h2>Details</h2>

            </div>
            {/* End Section Title */}
            <div className="container">
                <div className="row gy-4 align-items-center features-item">
                    <div
                        className="col-md-5 d-flex align-items-center"
                        data-aos="zoom-out"
                        data-aos-delay={100}
                    >
                        <img src="assets/img/details-1.png" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-7" data-aos="fade-up" data-aos-delay={100}>
                        <h3>
                            {t("Comment Filedis fonctionne ?")}
                        </h3>
                        <p className="fst-italic">
                            {t("Installez l'application et créez un compte pour votre commerce")}
                        </p>
                        <ul>
                            <li>
                                <i className="bi bi-check"/> {" "}
                                <span>{t("Ajoutez vos régles d'attribution de fidélité , par montant dépenser")}</span>
                            </li>
                            <li>
                                <i className="bi bi-check"/>{" "}
                                <span>{t("Ajoutez les récompenses que le client peut s'offrir avec ses points de fidélité")}</span>
                            </li>
                            <li>
                                <i className="bi bi-check"/>{" "}
                                <span>{t("Après le payement , laisser votre client scanner le QR code généré et valider sa transaction de fidélité")}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Features Item */}
                <div className="row gy-4 align-items-center features-item">
                    <div
                        className="col-md-5 order-1 order-md-2 d-flex align-items-center"
                        data-aos="zoom-out"
                        data-aos-delay={200}
                    >
                        <img src="assets/img/details-2.png" className="img-fluid" alt=""/>
                    </div>
                    <div
                        className="col-md-7 order-2 order-md-1"
                        data-aos="fade-up"
                        data-aos-delay={200}
                    >
                        <h3>{t("Créez des campagnes d'évenement pour attirer de la clientelle")}</h3>
                        <p className="fst-italic">
                            {t("Ajoutez de la dynamic à votre commerce avec des évements spécial sur une durée bien defini")}
                        </p>
                        <p>
                            {t("Ces évements seront transmis a vos clients les plus fidèles et ceux autour de vous qui pevent être intéréssé par vos produits et vos services")}
                        </p>
                        <p>
                            {t("C'est dans le but d'augmenter votre chiffre d'affaire durant ces évenments et faire propérer votre commerce")}
                        </p>
                    </div>
                </div>
                {/* Features Item */}
                <div className="row gy-4 align-items-center features-item">
                    <div className="col-md-5 d-flex align-items-center" data-aos="zoom-out">
                        <img src="assets/img/details-3.png" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-7" data-aos="fade-up">
                        <h3>
                            {t("Vos clients pourrant centraliser leur fidélité")}
                        </h3>
                        <p>
                            {t("Vos clients ne seront pas obligé d'avoir multiple application et carte de fidélité , tout est centraliser en un seul outils simple et éfficace")}
                        </p>
                        <ul>
                            <li>
                                <i className="bi bi-check"/>{" "}
                                <span>{t("Vos clients peuvent suivre la progression de leur points et les récompenses a venir")}</span>
                            </li>
                            <li>
                                <i className="bi bi-check"/>{" "}
                                <span>{t("Vos clients seront les premiers au coup de vos évenements et vos bonnes affaires")}</span>
                            </li>
                            <li>
                                <i className="bi bi-check"/>{" "}
                                <span>{t("Vos clients aurant une vu global de leur activité dans votre commerce et ceux qu'ils fréquentent")}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Features Item */}
                <div className="row gy-4 align-items-center features-item">
                    <div
                        className="col-md-5 order-1 order-md-2 d-flex align-items-center"
                        data-aos="zoom-out"
                    >
                        <img src="assets/img/details-4.png" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-7 order-2 order-md-1" data-aos="fade-up">
                        <h3>
                            {t("Avec des vus statitique global vous vous adapter")}
                        </h3>
                        <p className="fst-italic">
                            {t("Adapter vous avec nos analyses de vos performances avec notre IA")}
                        </p>
                        <p>
                            {t("Pour être dynamique et suivrve la tendance de vos clients vous pouvez vous mettre à jours et adapter vos règles, vos récompenses et vos compagnes d'évements à tout moments pour garantir un rendement optimal de votre commerce en suivant les recommendations généré par notre IA d'analyse")}
                        </p>
                    </div>
                </div>
                {/* Features Item */}
            </div>
        </section>
        {/* /Details Section */}
    </>

}
export default Details
