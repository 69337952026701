import PageWrapper from "../../commons/PageWrapper";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Simulate} from "react-dom/test-utils";
import * as amplitude from '@amplitude/analytics-browser';


//@ts-ignore
const ScanRedirect = () => {
    amplitude.track('FILEDIS QR SCAN');
    const [searchParams] = useSearchParams()
    const [deepLinkValue , setDeepLinkValue] = useState("")
    const iOS = () => {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    useEffect(() => {
        const paramsAsObject = Object.fromEntries(searchParams);

        console.log("bloc ", paramsAsObject)
        const bloc = paramsAsObject.block

        //http://10.0.2.2:3000/scan?block=123
        const deepLink = `filedis://scan/${bloc}`
        setDeepLinkValue(deepLink)
        // @ts-ignore
        window.location= deepLink ; // will result in error message if app not installed


    } , [])



    const getapp = () => {
        if(iOS()){

            // @ts-ignore
            window.location = "https://apps.apple.com/fr/app/filedis-pro/id6670335013"; // will result in error message if app not installed

        }else{

            // @ts-ignore
            window.location = "https://play.google.com/store/apps/details?id=com.filedis.filedispaymentseller"; // will result in error message if app not installed

        }
    }
    return <PageWrapper isHeaderAvailable={false}>
        <div>
            <section id="about" className="about section">
                <div className="container" data-aos="fade-up" data-aos-delay={100}>
                    <div className="row align-items-xl-center gy-5">
                        <div className="col-lg-3 content">
                        </div>
                        <div className="col-lg-6 content" onClick={()=> {

                            //@ts-ignore
                            window.location = deepLinkValue
                        }}>
                            <div style={{display:"flex" , flexDirection:"column" , alignItems:"center"}}>

                                <div style={{display:"flex" , flexDirection:"row" , alignItems:"center" , gap: 10}}>
                                    <img src={"/assets/img/smartphone_icon.png"} width={100} height={100}/><span>Open Filedis</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 content">
                        </div>
                    </div>
                </div>

                <div className="container" data-aos="fade-up" data-aos-delay={100}>
                    <div className="row align-items-xl-center gy-5">
                        <div className="col-lg-3 content">
                        </div>
                        <div className="col-lg-6 content" onClick={getapp}>
                            <div style={{display:"flex" , flexDirection:"column" , alignItems:"center"}}>
                            <p>Get Filedis app on the store</p>
                            {iOS() && <img src={"/assets/img/btns/app-store-btn.png"} width={250} height={100}/>}
                            {!iOS() && <img src={"/assets/img/btns/google-play-btn.png"} width={250} height={100}/>}
                            </div>
                        </div>
                        <div className="col-lg-3 content">
                        </div>
                    </div>
                </div>
            </section>

        </div>

    </PageWrapper>
}
export default ScanRedirect
