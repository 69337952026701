import {useTranslation} from "react-i18next";

const Contact = () => {
    const [t] = useTranslation()
  return <>
      <section id="contact" className="contact section">
          {/* Section Title */}
          <div className="container section-title" data-aos="fade-up">
              <h2>{t("Contact")}</h2>
              <div>


              </div>
          </div>
          {/* End Section Title */}
          <div className="container" data-aos="fade" data-aos-delay={100}>
              <div className="row gy-4">
                  <div className="col-lg-4">
                      <div
                          className="info-item d-flex"
                          data-aos="fade-up"
                          data-aos-delay={200}
                      >
                          <i className="bi bi-geo-alt flex-shrink-0" />
                          <div>
                              <h3>{t("Adresse")}</h3>
                              <p>10 rue Camille Saint-Saens, 92500 Rueil Malmaison , France </p>
                          </div>
                      </div>

                      {/* End Info Item */}
                      <div
                          className="info-item d-flex"
                          data-aos="fade-up"
                          data-aos-delay={400}
                      >
                          <i className="bi bi-envelope flex-shrink-0" />
                          <div>
                              <h3>Email Us</h3>
                              <p>contact@branholo.com</p>
                          </div>
                      </div>
                      {/* End Info Item */}
                  </div>
                  <div className="col-lg-8">
                      <form
                          action="forms/contact.php"
                          method="post"
                          className="php-email-form"
                          data-aos="fade-up"
                          data-aos-delay={200}
                      >
                          <div className="row gy-4">
                              <div className="col-md-6">
                                  <input
                                      type="text"
                                      name="name"
                                      className="form-control"
                                      placeholder={t("Your Name")}
                                      required
                                  />
                              </div>
                              <div className="col-md-6 ">
                                  <input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      placeholder={t("Your Email")}
                                      required
                                  />
                              </div>
                              <div className="col-md-12">
                                  <input
                                      type="text"
                                      className="form-control"
                                      name="subject"
                                      placeholder={t("Subject")}
                                      required
                                  />
                              </div>
                              <div className="col-md-12">
                <textarea
                    className="form-control"
                    name="message"
                    rows={6}
                    placeholder={t("Message")}
                    required
                    defaultValue={""}
                />
                              </div>
                              <div className="col-md-12 text-center">
                                  <div className="loading">{t("Envoi en cours")}</div>
                                  <div className="error-message" />
                                  <div className="sent-message">
                                      {t("Votre message a été envoyé, nous vous répondrons dans le plus court délais")}
                                  </div>
                                  <button type="submit">{t("Envoyer un message")}</button>
                              </div>
                          </div>
                      </form>
                  </div>
                  {/* End Contact Form */}
              </div>
          </div>
      </section>
      {/* /Contact Section */}
  </>

}
export default Contact
