import {ReactNode} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

type Props = {
    children: ReactNode;
    isHeaderAvailable?: Boolean
}

const PageWrapper = ({children , isHeaderAvailable}: Props) => {

    return (
        <>
            <Header isHeaderAvailable={isHeaderAvailable}/>
            <main className={"main"}>
                {children}
            </main>
            <Footer/>
        </>
    )
}
export default PageWrapper
