const Footer = () => {
  return <footer id="footer" className="footer dark-background">
      <div className="container footer-top">
          <div className="row gy-4">
              <div className="col-lg-6 col-md-6 footer-about">
                  <a href="index.html" className="logo d-flex align-items-center">
                      <span className="sitename">Filedis by Branholo</span>
                  </a>
                  <div className="footer-contact pt-3">
                      <p>10 rue Camille Saint Saens</p>
                      <p>92500 Rueil Malmaison</p>
                      <p className="mt-3">

                      </p>
                      <p>
                          <strong>Email:</strong> <span>contact@branholo.com</span>
                      </p>
                  </div>
                  <div className="social-links d-flex mt-4"  >
                      <a href="https://www.facebook.com/profile.php?id=61564206479609" target={"_blank"}>
                          <i className="bi bi-facebook" />
                      </a>
                      <a href="https://www.instagram.com/filedis_app/"  target={"_blank"}>
                          <i className="bi bi-instagram" />
                      </a>
                      <a href="https://www.tiktok.com/@filedis6" target={"_blank"}>
                          <i className="bi bi-tiktok" />
                      </a>
                  </div>
              </div>
              <div className="col-lg-3 col-md-0 footer-links">
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                      <li>
                          <a href="/privacy-policy">Privacy policy</a>
                      </li>

                      <li>
                          <a href="/terme-of-use">Terme of use</a>
                      </li>

                      <li>
                          <a href="/eula">EULA</a>
                      </li>
                  </ul>
              </div>
              {/*<div className="col-lg-2 col-md-3 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                      <li>
                          <a href="#">Web Design</a>
                      </li>
                      <li>
                          <a href="#">Web Development</a>
                      </li>
                      <li>
                          <a href="#">Product Management</a>
                      </li>
                      <li>
                          <a href="#">Marketing</a>
                      </li>
                      <li>
                          <a href="#">Graphic Design</a>
                      </li>
                  </ul>
              </div>
                  <div className="col-lg-4 col-md-12 footer-newsletter">

                  </div>*/}
          </div>
      </div>
      <div className="container copyright text-center mt-4">
          <p>
              © <span>Copyright</span>{" "}
              <strong className="px-1 sitename">Branholo</strong>{" "}
              <span>All Rights Reserved</span>
          </p>

      </div>
  </footer>

}
export default Footer
