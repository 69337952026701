import {useTranslation} from "react-i18next";

type Props = {
  isHeaderAvailable?: Boolean
}
const Header = ({isHeaderAvailable}: Props) => {
  const [t, i18n] = useTranslation()

  const toggleMobileNav = () => {
    const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');
    if(mobileNavToggleBtn !== null){

      console.log("mobile nav click")
      document.querySelector('body')!!.classList.toggle('mobile-nav-active');
      mobileNavToggleBtn.classList.toggle('bi-list');
      mobileNavToggleBtn.classList.toggle('bi-x');
    }
  }

  const parseLang = (lang: string) => {
    if(lang == "en") {
      return "English"
    }
    if(lang == "fr"){
      return  "Français"
    }
    if(lang =="de"){
      return  "Deutch"
    }
    if(lang == "it"){
      return "Italian"
    }
    if(lang == "es"){
      return "Espaniol"
    }
  }

  const changeLang = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  return (<header id="header" className="header d-flex align-items-center fixed-top scrolled">
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            {/* <img src="assets/img/logo.png" alt=""> */}
            <h1 className="sitename">Filedis</h1>
          </a>
          {isHeaderAvailable !== true &&  <nav id="navmenu" className="navmenu">
           <ul>
             <li>
               <a href="/" className="active">
                 {t("Home")}
               </a>
             </li>
           </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={toggleMobileNav}/>

          </nav> }
          {isHeaderAvailable === true && <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <a href="#hero" className="active">
                  {t("Home")}
                </a>
              </li>
              <li>
                <a href="#about">{t("About")}</a>
              </li>
              <li>
                <a href="#features">{t("Features")}</a>
              </li>
              <li>
                <a href="#pricing">{t("Pricing")}</a>
              </li>

              <li>
                <a href="#contact">{t("Contact")}</a>
              </li>
              <li className="dropdown">
                <a href="#">
                  <span>{parseLang(i18n.language)}</span> <i className="bi bi-chevron-down toggle-dropdown"/>
                </a>
                <ul>
                  <li>
                    <a href="javascript:void(0)" onClick={() => {
                      changeLang("fr")
                    }}>Français</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={() => {
                      changeLang("en")
                    }}>English</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={() => {
                      changeLang("it")
                    }}>Italian</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={() => {
                      changeLang("de")
                    }}>Deutch</a>
                  </li>
                </ul>
              </li>

            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={toggleMobileNav}/>
          </nav>}
        </div>
      </header>
  )
}
export default Header
